export const slugify = (input) => {
  if (!input) {
    return;
  }
  let slug = input.toLowerCase().trim();
  const replaceSource = "ąęśżźćńłóó";
  const replaceDest = "aeszzcnloo";
  for (let i = 0; i < replaceSource.length - 1; i++) {
    slug = replaceAll(slug, replaceSource[i], replaceDest[i]);
  }
  slug = slug.replace(/[^a-z0-9\s-]/g, "_");
  slug = slug.replace(/[\s-]+/g, "-");
  return slug;
};

function replaceAll(string, search, replacement) {
  return string.replace(new RegExp(search, "g"), replacement);
}

export const getCaqiDescription = (caqi) => {
  if (caqi < 25) return "bardzo niski";
  if (caqi < 50) return "niski";
  if (caqi < 75) return "średni";
  if (caqi < 87.5) return "wysoki";
  if (caqi < 100) return "bardzo wysoki";
  return "ekstremalny";
};

export const getCaqiDescription2 = (caqi) => {
  if (caqi < 25) return "bardzo niskie";
  if (caqi < 50) return "niske";
  if (caqi < 75) return "średnie";
  if (caqi < 87.5) return "wysokie";
  if (caqi < 100) return "bardzo wysokie";
  return "ekstremalne";
};

export const outputTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  let hours = "0" + date.getHours();
  if (hours.length > 2) hours = hours.substring(1, 3);
  let minutes = "0" + date.getMinutes();
  if (minutes.length > 2) minutes = minutes.substring(1, 3);
  return hours + ":" + minutes;
};

export const outputTimeDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString("pl-pl", { month: "short" });
  const hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  if (minutes.length > 2) minutes = minutes.substring(1, 3);
  return day + " " + month + " " + hours + ":" + minutes;
};

export const outputDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString("pl-pl", { month: "long" });
  const year = date.getFullYear();
  return day + " " + month + " " + year;
};

export const getElementOffset = (el) => {
  const rect = el.getBoundingClientRect();
  return {
    left: Math.round(rect.left + window.scrollX),
    top: Math.round(rect.top + window.scrollY),
  };
};

export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));

export const getTimestamp = () => parseInt(new Date().getTime() / 1000);

export const sortAlpha = (data, key) =>
  data.sort((a, b) => a[key].localeCompare(b[key]));
