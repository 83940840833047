import React from "react";
import { getCaqiDescription2 } from "Utils/functions";
import { getCaqiColors } from "Utils/colors";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { selectActiveDevice } from "Components/ActiveDevice/activeDeviceSlice";

const Wrapper = styled.div`
  background-color: #ecebe0;
  box-sizing: border-box;
  padding: 5px 10px 10px;
  margin-bottom: -15px;
  width: 100%;
`;

const Widget = styled.div`
  height: 80px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
`;

const CaqiVal = styled.div`
  text-align: center;
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  line-height: 0.4;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.3);
  & span {
    font-size: 0.3em;
  }
`;

const PM25 = styled.div`
  position: absolute;
  top: 20px;
  right: 130px;
  font-size: 1.25em;
  line-height: 0.5;
  & span {
    font-weight: 600;
  }
`;

const PM10 = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
  font-size: 1.25em;
  line-height: 0.5;
  & span {
    font-weight: 600;
  }
`;

const LevelDescription = styled.div`
  position: absolute;
  right: 10px;
  bottom: 15px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  line-height: 1;
  color: #fff;
  & strong {
    font-weight: 500;
  }
`;

function MobileWidget() {
  const device = useSelector(selectActiveDevice);

  if (!device) return "";
  const { caqi, pm25, pm10 } = device;
  const style = { ...getCaqiColors(caqi) };

  return (
    <Wrapper>
      <Widget style={style}>
        <CaqiVal>
          {caqi} <br />
          <span>CAQI</span>
        </CaqiVal>
        <LevelDescription>
          Zanieczyszczenie: <strong>{getCaqiDescription2(caqi)}</strong>
        </LevelDescription>
        <PM25>
          <small>PM 2,5:</small> <span>{pm25}</span>
        </PM25>
        <PM10>
          <small>PM 10:</small> <span>{pm10}</span>
        </PM10>
      </Widget>
    </Wrapper>
  );
}

export default MobileWidget;
